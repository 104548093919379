module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RIDIPAPER","short_name":"PAPER","icon":"src/assets/images/meta/favicon-512x512.png","icons":[{"src":"/favicons/48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/128.png","sizes":"128x128","type":"image/png"},{"src":"/favicons/192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/196.png","sizes":"196x196","type":"image/png"},{"src":"/favicons/256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/512.png","sizes":"512x512","type":"image/png"}],"theme_color":"#ffffff","background_color":"#ffffff","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ce6788cceaedee4325388abab3777ec9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-10567409-11","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Noto Sans KR:300,400,700:latin,korean"]},"custom":{"families":["RIDIBatang","Pretendard"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
